import { ErrorCard } from "@components/Card";
import { AutoColumn } from "@components/Layout/Column";
import { RowBetween } from "@components/Layout/Row";
import { CurrencyLogo } from "@components/Logo";
import Box from "@mui/material/Box";
import type { Currency } from "@vapordex/sdk";
import { Button, InfoSuccessIcon, Text } from "@vapordex/uikit";
import { useFeature } from "@vaporfi/feature-flags";
import { Typography } from "@vaporfi/uikit";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useState } from "react";
import styled from "styled-components";
import { formatUnits } from "viem";
import * as swapAnimationData from "../../../../public/animations/swapping.json";
import PriceImpactWarning from "./PriceImpact";
import SwapRoute from "./SwapRoute";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const SwapSummaryFooter = ({
	allowedSlippage,
	amountOut,
	fee,
	isStratosphereMember,
	offer,
	outputCurrency,
	stablecoinPriceImpact,
}) => {
	const { isEnabled } = useFeature();

	return (
		<AutoColumn
			gap="10px"
			style={{ padding: 0, paddingTop: "10px" }}
			className="w-full"
		>
			<RowBetween align="center">
				<Text
					style={{
						color: "rgba(115, 115, 115, 1)",
						fontSize: 13,
						fontWeight: 400,
						lineHeight: "16px",
					}}
				>
					Slippage Tolerance
				</Text>
				<Text
					data-testid="swap-slippage-tolerance"
					style={{ color: "rgba(115, 115, 115, 1)", fontSize: 13 }}
				>
					{allowedSlippage / 100}%
				</Text>
			</RowBetween>

			{!!offer?.path?.length && (
				<>
					<RowBetween align="center">
						<Label style={{ color: "#737373", fontSize: 12 }}>
							{"Minimum received"}
						</Label>
						<Text
							bold
							style={{ color: "#737373", fontSize: 12 }}
							data-testid="swap-min-received"
						>
							{formatUnits(amountOut.toString(), outputCurrency.decimals)}
						</Text>
					</RowBetween>

					<RowBetween align="center">
						<Label style={{ color: "#737373", fontSize: 12 }}>
							{"Service fee"}
						</Label>
						<Text
							bold
							style={{ color: "#737373", fontSize: 12 }}
							data-testid="swap-service-fee"
						>
							0.1%
						</Text>
					</RowBetween>
					{isStratosphereMember && (
						<>
							<RowBetween align="center">
								<Label
									style={{
										color: "#14D3ED",
										fontSize: 12,
										fontWeight: "300",
										marginLeft: 4,
									}}
								>
									🎉 {"Stratosphere discount"}
								</Label>
								<Text
									data-testid="swap-strat-discount"
									bold
									style={{ color: "#14D3ED", fontSize: 12, fontWeight: "300" }}
								>
									1%
								</Text>
							</RowBetween>

							<RowBetween align="center">
								<Label style={{ color: "#FFFFFF", fontSize: 12 }}>
									{"Net service fee"}
								</Label>
								<Text
									bold
									style={{ color: "#FFFFFF", fontSize: 12 }}
									data-testid="swap-strat-discount"
								>
									{fee / 1_000_000}%
								</Text>
							</RowBetween>
						</>
					)}
					{isEnabled("enablePriceImpact") &&
						stablecoinPriceImpact !== null &&
						stablecoinPriceImpact > 2 && (
							<PriceImpactWarning
								priceImpact={stablecoinPriceImpact}
								status={true}
							/>
						)}
					<SwapRoute data-testid="swap-route-component" offer={offer} />
				</>
			)}
		</AutoColumn>
	);
};

export const PendingSwapState = ({
	children,
	inputCurrency,
	inputFormattedCurrency,
	outputCurrency,
	outputFormattedCurrency,
}) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			borderRadius={3}
			paddingX={3}
			paddingY={4}
			sx={{
				background: "#000000 !important",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				position="relative"
				paddingTop={0}
				marginBottom={3}
			>
				<Box marginBottom={1}>
					<Image
						src="/images/VaporDexProcessing.svg"
						height={31}
						width={50}
						alt="Processing..."
					/>
				</Box>
				<Box>
					<Text data-testid="swap-pending" fontSize={16} fontWeight="bold">
						{"Swap Pending"}
					</Text>
				</Box>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				justifyContent="center"
				position="relative"
				padding={2}
				marginBottom={3}
				borderRadius="5px"
				sx={{
					background: "#171717 !important",
				}}
			>
				<Box display="flex" marginBottom={2} flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={inputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>{" "}
					<Text>{inputCurrency?.symbol}</Text>
					<Text>{inputFormattedCurrency}</Text>
				</Box>
				<Box color="white" marginBottom={2}>
					<Text>for</Text>
				</Box>
				<Box display="flex" flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={outputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>
					<Text>{outputCurrency?.symbol}</Text>
					<Text>{outputFormattedCurrency}</Text>
				</Box>
			</Box>

			{children}
		</Box>
	);
};

export const SuccessSwapState = ({
	children,
	inputCurrency,
	inputFormattedCurrency,
	isStratosphereMember,
	onTransactionCompleteClick,
	outputCurrency,
	outputFormattedCurrency,
}) => {
	const [showAnimation, setShowAnimation] = useState<boolean>(true);

	const defaultOptions = {
		animationData: swapAnimationData,
		autoplay: true,
		loop: false,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	if (showAnimation) {
		return (
			<Lottie
				options={defaultOptions}
				height={320}
				width={320}
				eventListeners={[
					{
						callback: () => setShowAnimation(false),
						eventName: "complete",
					},
				]}
			/>
		);
	}

	return (
		<Box
			data-testid="swap-success-state"
			display="flex"
			flexDirection="column"
			borderRadius={3}
			paddingX={3}
			paddingY={4}
			sx={{
				background: "#000000 !important",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				position="relative"
				paddingTop={0}
				marginBottom={3}
			>
				<Box marginBottom={1}>
					<Image
						src="/images/VaporDexComplete.svg"
						height={31}
						width={50}
						alt="Completed!"
					/>
				</Box>
				<Box>
					<Text data-testid="swap-complete" fontSize={16} fontWeight="bold">
						{"Swap Complete"}
					</Text>
				</Box>

				{!isStratosphereMember && (
					<Box
						marginTop={2}
						display="flex"
						sx={{
							border: "2px solid #14d3ed",
							borderRadius: "10px",
							boxSizing: "border-box",
							gap: "12px",
							padding: "20px",
						}}
					>
						<Box>
							<InfoSuccessIcon />
						</Box>
						<Box maxWidth={220}>
							<Text data-testid="swap-complete-potential-saved">
								You could have saved up to 4% on this swap with{" "}
								<span
									style={{ fontFamily: "Manverse Normal", fontWeight: 400 }}
								>
									STRATOSPHERE
								</span>
							</Text>
						</Box>
					</Box>
				)}
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				justifyContent="center"
				position="relative"
				padding={2}
				marginBottom={3}
				borderRadius="5px"
				sx={{
					background: "#171717 !important",
				}}
			>
				<Box display="flex" marginBottom={2} flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={inputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>{" "}
					<Text data-testid="swap-complete-input-symbol">
						{inputCurrency?.symbol}
					</Text>
					<Text data-testid="swap-complete-input-result">
						{(+inputFormattedCurrency)?.toFixed(4)}
					</Text>
				</Box>
				<Box color="white" marginBottom={2}>
					<Text>for</Text>
				</Box>
				<Box display="flex" flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={outputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>
					<Text data-testid="swap-complete-output-symbol">
						{outputCurrency?.symbol}
					</Text>
					<Text data-testid="swap-complete-output-result">
						{(+outputFormattedCurrency)?.toFixed(4)}
					</Text>
				</Box>
			</Box>

			{children}

			<Box marginTop={2}>
				<Button
					data-testid="swap-complete-done-btn"
					width="100%"
					onClick={onTransactionCompleteClick}
				>
					{"Done"}
				</Button>
			</Box>
		</Box>
	);
};

export const FailedSwapState = ({
	children,
	inputCurrency,
	inputFormattedCurrency,
	message,
	onTransactionCompleteClick,
	outputCurrency,
	outputFormattedCurrency,
}: {
	inputCurrency: Currency;
	outputCurrency: Currency;
	inputFormattedCurrency: string;
	outputFormattedCurrency: string;
	onTransactionCompleteClick: () => void;
	message: string;
	children: React.ReactElement;
}) => {
	return (
		<Box
			data-testid="swap-failed-state"
			display="flex"
			flexDirection="column"
			borderRadius={3}
			paddingX={3}
			paddingY={4}
			sx={{
				background: "#000000 !important",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				position="relative"
				paddingTop={0}
				marginBottom={3}
			>
				<Box marginBottom={1}>
					<Image
						src="/images/VaporDEXFailed.svg"
						height={31}
						width={50}
						alt="Failed!"
					/>
				</Box>
				<Box>
					<Text fontSize={16} fontWeight="bold">
						{"Swap Failed"}
					</Text>
				</Box>
			</Box>
			<Box marginBottom={3} justifyContent="center">
				<ErrorCard>
					<Typography style={{ textAlign: "center" }}>
						{message ?? "ERROR"}
					</Typography>
				</ErrorCard>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				alignItems="center"
				justifyContent="center"
				position="relative"
				padding={2}
				marginBottom={3}
				borderRadius="5px"
				sx={{
					background: "#171717 !important",
				}}
			>
				<Box display="flex" marginBottom={2} flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={inputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>
					<Text data-testid="swap-failed-input-symbol">
						{inputCurrency?.symbol}
					</Text>
					<Text data-testid="swap-failed-input-result">
						{inputFormattedCurrency}
					</Text>
				</Box>
				<Box color="white" marginBottom={2}>
					<Text>{"for"}</Text>
				</Box>
				<Box display="flex" flexDirection="row" gap={1}>
					<CurrencyLogo
						currency={outputCurrency}
						size={24}
						style={{ marginRight: "8px" }}
					/>
					<Text data-testid="swap-failed-output-symbol">
						{outputCurrency?.symbol}
					</Text>
					<Text data-testid="swap-failed-output-result">
						{outputFormattedCurrency}
					</Text>
				</Box>
			</Box>

			{children}

			<Box marginTop={2}>
				<Button
					data-testid="swap-failed-done-btn"
					width="100%"
					onClick={onTransactionCompleteClick}
				>
					{"Done"}
				</Button>
			</Box>
		</Box>
	);
};
