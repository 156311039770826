import tokens from "config/constants/tokens";

const MIN_VALUE_DISPLAYED = 0.001;

export const getTimeWindowChange = (lineChartData) => {
	if (lineChartData.length > 0) {
		const firstValue =
			lineChartData.find(({ value }) => !!value && value > 0)?.value ?? 0;
		const lastValue = lineChartData.at(-1).value;
		const changeValue = lastValue - firstValue;

		return {
			changePercentage: ((changeValue / firstValue) * 100).toFixed(2),
			changeValue:
				changeValue > 0
					? Math.max(changeValue, MIN_VALUE_DISPLAYED)
					: Math.min(changeValue, MIN_VALUE_DISPLAYED * -1),
		};
	}

	return {
		changePercentage: 0,
		changeValue: 0,
	};
};

export const getTokenAddress = (tokenAddress: undefined | string) => {
	if (!tokenAddress) {
		return "";
	}
	const lowerCaseAddress = tokenAddress.toLowerCase();
	if (lowerCaseAddress === "avax") {
		return tokens().wavax.address;
	}

	return lowerCaseAddress;
};
