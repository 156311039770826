import { chainIdToName } from "@config/constants/networks";
import { useEffect, useState } from "react";
import useSWR from "swr";

const fetcher = (url) =>
	fetch(url).then((res) => {
		if (!res.ok) {
			throw new Error(`API returned status code ${res.status}`);
		}
		return res.json().catch(() => {
			throw new Error("Response was not valid JSON");
		});
	});

const resolveChainName = (chainId) => {
	if (chainId === undefined) {
		return null;
	}
	return chainIdToName[chainId.toString()] || null;
};

export function useTokenPrice({
	chainId,
	isNativeCurrency = false,
	tokenAddress,
}) {
	const chainName = resolveChainName(chainId);
	const address = tokenAddress
		? tokenAddress.toLowerCase()
		: "0x0000000000000000000000000000000000000000";

	const [apiUrl, setApiUrl] = useState("");

	useEffect(() => {
		// Default to Covalent API
		const covalentEndpoint = chainName
			? `/api/swap/tokenPrice/covalent?chainId=${chainId}&tokenAddress=${address}&isNativeCurrency=${isNativeCurrency}`
			: null;
		setApiUrl(covalentEndpoint);
	}, [chainName, chainId, address, isNativeCurrency]);

	const { data, error } = useSWR(apiUrl, fetcher, {
		shouldRetryOnError: false,
	});

	useEffect(() => {
		if (error) {
			// If Covalent API fails, switch to CoinGecko API
			const coinGeckoEndpoint = `/api/swap/tokenPrice/coingecko?chainId=${chainId}&tokenAddress=${address}&isNativeCurrency=${isNativeCurrency}`;
			setApiUrl(coinGeckoEndpoint);
		}
	}, [error, chainId, address, isNativeCurrency]);

	const isLoading = !data && !error;
	const isError = !!error;
	const price = data ? data.priceInUsd || data.price : null;

	return {
		isError,
		isLoading,
		price,
	};
}
