import type { Currency, Percent, TradeType } from "@vapordex/sdk";
import type { Trade } from "@vapordex/sdk";
import { ONE_HUNDRED_PERCENT, ZERO_PERCENT } from "config/constants/exchange";

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
export function isTradeBetter(
	tradeA: Trade<Currency, Currency, TradeType> | undefined | null,
	tradeB: Trade<Currency, Currency, TradeType> | undefined | null,
	minimumDelta: Percent = ZERO_PERCENT,
): boolean | undefined {
	if (tradeA && !tradeB) return false;
	if (tradeB && !tradeA) return true;
	if (!tradeA || !tradeB) return undefined;

	if (
		tradeA.tradeType !== tradeB.tradeType ||
		!tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
		!tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency)
	) {
		throw new Error("Trades are not comparable");
	}

	if (minimumDelta.equalTo(ZERO_PERCENT)) {
		return tradeA.executionPrice.lessThan(tradeB.executionPrice);
	}
	return tradeA.executionPrice.asFraction
		.multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
		.lessThan(tradeB.executionPrice);
}

export default isTradeBetter;
