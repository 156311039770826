/* eslint-disable no-param-reassign */
import { type Pair, Trade } from "@vapordex/sdk";
import type { Currency, CurrencyAmount, Token, TradeType } from "@vapordex/sdk";
import {
	ADDITIONAL_BASES,
	BASES_TO_CHECK_TRADES_AGAINST,
	BETTER_TRADE_LESS_HOPS_THRESHOLD,
	CUSTOM_BASES,
} from "config/constants/exchange";
import useActiveWagmi from "hooks/useActiveWagmi";
import flatMap from "lodash/flatMap";
import { useMemo } from "react";
import { useUserSingleHopOnly } from "state/user/hooks";
import { isTradeBetter } from "utils/trades";
import { useUnsupportedTokens, useWarningTokens } from "./Tokens";
import { PairState, usePairs } from "./usePairs";

export function useAllCommonPairs(
	currencyA?: Currency,
	currencyB?: Currency,
): Pair[] {
	const { chainId } = useActiveWagmi();

	const [tokenA, tokenB] = chainId
		? [currencyA?.wrapped, currencyB?.wrapped]
		: [undefined, undefined];

	const bases: Token[] = useMemo(() => {
		if (!chainId) return [];

		const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? [];
		const additionalA = tokenA
			? (ADDITIONAL_BASES[chainId]?.[tokenA?.wrapped?.address] ?? [])
			: [];
		const additionalB = tokenB
			? (ADDITIONAL_BASES[chainId]?.[tokenB?.wrapped?.address] ?? [])
			: [];

		return [...common, ...additionalA, ...additionalB];
	}, [chainId, tokenA, tokenB]);

	const basePairs: [Token, Token][] = useMemo(
		() =>
			flatMap(bases, (base): [Token, Token][] =>
				bases.map((otherBase) => [base, otherBase]),
			),
		[bases],
	);

	const allPairCombinations: [Token, Token][] = useMemo(
		() =>
			tokenA && tokenB
				? [
						// the direct pair
						[tokenA, tokenB],
						// token A against all bases
						...bases.map((base): [Token, Token] => [tokenA, base]),
						// token B against all bases
						...bases.map((base): [Token, Token] => [tokenB, base]),
						// each base against all bases
						...basePairs,
					]
						.filter((tokens): tokens is [Token, Token] =>
							Boolean(tokens[0] && tokens[1]),
						)
						.filter(([t0, t1]) => t0.address !== t1.address)
						.filter(([tokenA_, tokenB_]) => {
							if (!chainId) return true;
							const customBases = CUSTOM_BASES[chainId];

							const customBasesA: Token[] | undefined =
								customBases?.[tokenA_.address];
							const customBasesB: Token[] | undefined =
								customBases?.[tokenB_.address];

							if (!customBasesA && !customBasesB) return true;

							if (
								customBasesA &&
								!customBasesA.some((base) => tokenB_.equals(base))
							)
								return false;
							if (
								customBasesB &&
								!customBasesB.some((base) => tokenA_.equals(base))
							)
								return false;

							return true;
						})
				: [],
		[tokenA, tokenB, bases, basePairs, chainId],
	);

	const allPairs = usePairs(allPairCombinations);

	// only pass along valid pairs, non-duplicated pairs
	return useMemo(
		() =>
			Object.values(
				allPairs
					// filter out invalid pairs
					.filter((result): result is [PairState.EXISTS, Pair] =>
						Boolean(result[0] === PairState.EXISTS && result[1]),
					)
					// filter out duplicated pairs
					.reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
						memo[curr.liquidityToken.address] =
							memo[curr.liquidityToken.address] ?? curr;
						return memo;
					}, {}),
			),
		[allPairs],
	);
}

const MAX_HOPS = 3;

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
	currencyAmountIn?: CurrencyAmount<Currency>,
	currencyOut?: Currency,
): Trade<Currency, Currency, TradeType> | null {
	const allowedPairs = useAllCommonPairs(
		currencyAmountIn?.currency,
		currencyOut,
	);

	const [singleHopOnly] = useUserSingleHopOnly();

	return useMemo(() => {
		if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
			if (singleHopOnly) {
				return (
					Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
						maxHops: 1,
						maxNumResults: 1,
					})[0] ?? null
				);
			}
			// search through trades with varying hops, find best trade out of them
			let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null;
			for (let i = 1; i <= MAX_HOPS; i++) {
				const currentTrade: Trade<Currency, Currency, TradeType> | null =
					Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
						maxHops: i,
						maxNumResults: 1,
					})[0] ?? null;
				// if current trade is best yet, save it
				if (
					isTradeBetter(
						bestTradeSoFar,
						currentTrade,
						BETTER_TRADE_LESS_HOPS_THRESHOLD,
					)
				) {
					bestTradeSoFar = currentTrade;
				}
			}
			return bestTradeSoFar;
		}

		return null;
	}, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly]);
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
	currencyIn?: Currency,
	currencyAmountOut?: CurrencyAmount<Currency>,
): Trade<Currency, Currency, TradeType> | null {
	const allowedPairs = useAllCommonPairs(
		currencyIn,
		currencyAmountOut?.currency,
	);

	const [singleHopOnly] = useUserSingleHopOnly();

	return useMemo(() => {
		if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
			if (singleHopOnly) {
				return (
					Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
						maxHops: 1,
						maxNumResults: 1,
					})[0] ?? null
				);
			}
			// search through trades with varying hops, find best trade out of them
			let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null;
			for (let i = 1; i <= MAX_HOPS; i++) {
				const currentTrade =
					Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
						maxHops: i,
						maxNumResults: 1,
					})[0] ?? null;
				if (
					isTradeBetter(
						bestTradeSoFar,
						currentTrade,
						BETTER_TRADE_LESS_HOPS_THRESHOLD,
					)
				) {
					bestTradeSoFar = currentTrade;
				}
			}
			return bestTradeSoFar;
		}
		return null;
	}, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly]);
}

export function useIsTransactionUnsupported(
	currencyIn?: Currency,
	currencyOut?: Currency,
): boolean {
	const unsupportedTokens: { [address: string]: Token } =
		useUnsupportedTokens();

	const tokenIn = currencyIn?.wrapped;
	const tokenOut = currencyOut?.wrapped;

	// if unsupported list loaded & either token on list, mark as unsupported
	if (unsupportedTokens) {
		if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
			return true;
		}
		if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
			return true;
		}
	}

	return false;
}

export function useIsTransactionWarning(
	currencyIn?: Currency,
	currencyOut?: Currency,
): boolean {
	const unsupportedTokens: { [address: string]: Token } = useWarningTokens();

	const tokenIn = currencyIn?.wrapped;
	const tokenOut = currencyOut?.wrapped;

	// if unsupported list loaded & either token on list, mark as unsupported
	if (unsupportedTokens) {
		if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
			return true;
		}
		if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
			return true;
		}
	}

	return false;
}
