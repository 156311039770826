import { JSBI } from "@vapordex/sdk";
import { type Currency, Price } from "@vapordex/sdk";
import { WRAPPED_NATIVE_CURRENCY } from "@vaporfi/utils";
import tokens, { DEFAULT_STABLE_COIN } from "config/constants/tokens";
import useActiveWagmi from "hooks/useActiveWagmi";
import { useMemo } from "react";
import { multiplyPriceByAmount } from "utils/prices";
import { PairState, usePairs } from "./usePairs";

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useStableCoinPrice(
	currency?: Currency,
): Price<Currency, Currency> | undefined {
	const { chainId } = useActiveWagmi();
	const wrapped = currency?.wrapped;
	const wrappedNative = WRAPPED_NATIVE_CURRENCY[chainId];
	const stableCoin = DEFAULT_STABLE_COIN[chainId];
	const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
		() => [
			[
				chainId && wrapped && wrappedNative.equals(wrapped)
					? undefined
					: currency,
				chainId ? wrappedNative : undefined,
			],
			[wrapped?.equals(stableCoin) ? undefined : wrapped, stableCoin],
			[chainId ? wrappedNative : undefined, stableCoin],
		],
		[chainId, currency, stableCoin, wrapped, wrappedNative],
	);
	const [
		[avaxPairState, avaxPair],
		[stableCoinPairState, stableCoinPair],
		[stableCoinAvaxPairState, stableCoinAvaxPair],
	] = usePairs(tokenPairs);

	return useMemo(() => {
		if (!currency || !wrapped || !chainId) {
			return;
		}
		// handle wrappedNative/avax
		if (wrapped.equals(wrappedNative)) {
			if (stableCoinPair) {
				const price = stableCoinPair.priceOf(wrappedNative);
				return new Price(
					currency,
					stableCoin,
					price.denominator,
					price.numerator,
				);
			}
			return;
		}
		// handle usdc
		if (wrapped.equals(stableCoin)) {
			return new Price(stableCoin, stableCoin, "1", "1");
		}

		const avaxPairAVAXAmount = avaxPair?.reserveOf(wrappedNative);
		const avaxPairAVAXstableCoinValue: JSBI =
			avaxPairAVAXAmount && stableCoinAvaxPair
				? stableCoinAvaxPair.priceOf(wrappedNative).quote(avaxPairAVAXAmount)
						.quotient
				: JSBI.BigInt(0);

		// all other tokens
		// first try the usdc pair
		if (
			stableCoinPairState === PairState.EXISTS &&
			stableCoinPair &&
			stableCoinPair
				.reserveOf(stableCoin)
				.greaterThan(avaxPairAVAXstableCoinValue)
		) {
			const price = stableCoinPair.priceOf(wrapped);
			return new Price(
				currency,
				stableCoin,
				price.denominator,
				price.numerator,
			);
		}
		if (
			avaxPairState === PairState.EXISTS &&
			avaxPair &&
			stableCoinAvaxPairState === PairState.EXISTS &&
			stableCoinAvaxPair &&
			stableCoinAvaxPair.reserveOf(stableCoin).greaterThan("0") &&
			avaxPair.reserveOf(wrappedNative).greaterThan("0")
		) {
			const avaxstableCoinPrice = stableCoinAvaxPair.priceOf(stableCoin);
			const currencyAvaxPrice = avaxPair.priceOf(wrappedNative);
			const stableCoinPrice = avaxstableCoinPrice
				.multiply(currencyAvaxPrice)
				.invert();
			return new Price(
				currency,
				stableCoin,
				stableCoinPrice.denominator,
				stableCoinPrice.numerator,
			);
		}

		return;
	}, [
		currency,
		wrapped,
		chainId,
		wrappedNative,
		stableCoin,
		avaxPair,
		stableCoinAvaxPair,
		stableCoinPairState,
		stableCoinPair,
		avaxPairState,
		stableCoinAvaxPairState,
	]);
}

export const useCakeUsdcPrice = (): Price<Currency, Currency> | undefined => {
	const cakeUsdcPrice = useStableCoinPrice(tokens().cake);
	return cakeUsdcPrice;
};

export const useUSDCCurrencyAmount = (
	currency?: Currency,
	amount?: number,
): number | undefined => {
	const usdcPrice = useStableCoinPrice(currency);
	if (!amount) {
		return undefined;
	}
	if (usdcPrice) {
		return multiplyPriceByAmount(usdcPrice, amount);
	}
	return undefined;
};

export const useUSDCCakeAmount = (amount: number): number | undefined => {
	const cakeUsdcPrice = useCakeUsdcPrice();
	if (cakeUsdcPrice) {
		return multiplyPriceByAmount(cakeUsdcPrice, amount);
	}
	return undefined;
};

export const useNativeStablePrice = ():
	| Price<Currency, Currency>
	| undefined => {
	const { chainId } = useActiveWagmi();
	const nativeStablePrice = useStableCoinPrice(
		WRAPPED_NATIVE_CURRENCY[chainId],
	);
	return nativeStablePrice;
};
