import { type AppState, useAppDispatch } from "@state";
import { setIsExchangeChartDisplayed } from "@state/user/actions";
import { useCallback } from "react";
import { useSelector } from "react-redux";

// Get user preference for exchange price chart
// For mobile layout chart is hidden by default
export function useExchangeChartManager(
	isMobile: boolean,
): [boolean, (isDisplayed: boolean) => void] {
	const dispatch = useAppDispatch();
	const isChartDisplayed = useSelector<
		AppState,
		AppState["user"]["isExchangeChartDisplayed"]
	>((state) => state.user.isExchangeChartDisplayed);

	const setUserChartPreference = useCallback(
		(isDisplayed: boolean) => {
			dispatch(setIsExchangeChartDisplayed(isDisplayed));
		},
		[dispatch],
	);

	return [isMobile ? false : isChartDisplayed, setUserChartPreference];
}

export default useExchangeChartManager;
