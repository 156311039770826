import { StyledCard, StyledCardInner } from "./StyledCard";
import type { CardProps } from "./types";

const Card = ({ ribbon, children, background, ...props }: CardProps) => {
	return (
		<StyledCard {...props}>
			<StyledCardInner
				background={background}
				hasCustomBorder={!!props.borderBackground}
			>
				{ribbon}
				{children}
			</StyledCardInner>
		</StyledCard>
	);
};

export default Card;
