import Header from "@components/Header";
import {
	ALL_SUPPORTED_CHAIN_IDS,
	SupportedChainId,
} from "@config/constants/chains";
import useCurrency from "@hooks/useCurrency"; // 182kb
import { Field } from "@state/swap/actions";
import { useDefaultsFromURLSearch, useSwapState } from "@state/swap/hooks";
import { Container } from "@vaporfi/uikit";
import Page from "@views/Page";
/* eslint-disable unicorn/no-await-expression-member */
import type { GetServerSideProps } from "next";

/**
 * @dev Hitesh
 * we should NOT dynamic load entire component, instead dynamic load it's parts in it's own implementation
 * we may reduce the build time by loading everything dynamically, but page speed is more important, build time is secondary
 * I'll do rest optimizations later, after LM
 */

import { getFeature } from "@vaporfi/feature-flags";
import Swap from "@views/Swap";

const INPUT_CURRENCY_ID = "avalanche-2";
const OUTPUT_CURRENCY_ID = "usd-coin";

export const getServerSideProps: GetServerSideProps = async (context) => {
	let isChartEnabled = false;
	let isTermsEnabled = false;
	try {
		[isChartEnabled, isTermsEnabled] = await Promise.all([
			await getFeature("enableSwapChart"),
			await getFeature("enableSwapTerms"),
		]);

		if (!isChartEnabled) {
			return {
				props: {
					coinGeckoData: null,
					initialChartData: null,
					initialData: null,
					initialMarketData: null,
					inputToken: INPUT_CURRENCY_ID,
					isChartEnabled: false,
					isTermsEnabled: false,
					outputToken: OUTPUT_CURRENCY_ID,
				},
			};
		}
	} catch (error) {
		console.error(error);
		return {
			props: {
				coinGeckoData: null,
				initialChartData: null,
				initialData: null,
				initialMarketData: null,
				inputToken: INPUT_CURRENCY_ID,
				isChartEnabled,
				isTermsEnabled,
				outputToken: OUTPUT_CURRENCY_ID,
			},
		};
	}

	context.res.setHeader(
		"Cache-Control",
		"public, s-maxage=10, stale-while-revalidate=59",
	);

	try {
		const [coingecko_data, initialData, initialMarketData] = await Promise.all([
			(
				await fetch(
					"https://gist.githubusercontent.com/pablohpsilva/5133006e971bce5aa39c67656c72bd27/raw/ab5893f060c1609b969905e89b93978cd6f6ee32/coingecko_data.json",
				)
			).json(),
			(
				await fetch(
					`https://api.coingecko.com/api/v3/coins/${INPUT_CURRENCY_ID}/market_chart?vs_currency=usd&days=${1}`,
				)
			).json(),
			(
				await fetch(
					`https://api.coingecko.com/api/v3/coins/${INPUT_CURRENCY_ID}`,
				)
			).json(),
		]);

		return {
			props: {
				coinGeckoData: coingecko_data,
				initialChartData:
					initialData?.prices?.map((d) =>
						d.reduce((time, value) => ({
							time: Number(time) / 1000,
							value: Number(value),
						})),
					) ?? null,
				initialData,
				initialMarketData,
				inputToken: INPUT_CURRENCY_ID,
				isChartEnabled,
				outputToken: OUTPUT_CURRENCY_ID,
			},
		};
	} catch {
		return {
			props: {
				coinGeckoData: null,
				initialChartData: null,
				initialData: null,
				initialMarketData: null,
				inputToken: INPUT_CURRENCY_ID,
				isChartEnabled: false,
				outputToken: OUTPUT_CURRENCY_ID,
			},
		};
	}
};

const SwapPage = ({ pageProps }) => {
	// swap state & price data
	const {
		[Field.INPUT]: { currencyId: inputCurrencyId },
		[Field.OUTPUT]: { currencyId: outputCurrencyId },
		independentField,
		recipient,
		typedValue,
	} = useSwapState();

	const loadedUrlParams = useDefaultsFromURLSearch();
	const [loadedInputCurrency, loadedOutputCurrency] = [
		useCurrency(loadedUrlParams?.inputCurrencyId),
		useCurrency(loadedUrlParams?.outputCurrencyId),
	];
	const inputCurrency = useCurrency(inputCurrencyId);
	const outputCurrency = useCurrency(outputCurrencyId);

	const _inputCurrency = loadedInputCurrency ?? inputCurrency;
	const _outputCurrency = loadedOutputCurrency ?? outputCurrency;

	return (
		<Container fullWidth stack="column" itemsCenter>
			<Header
				breadcrumbs={[
					{
						currenciesLabel: _inputCurrency
							? `${_inputCurrency?.symbol} ${
									_outputCurrency ? `/ ${_outputCurrency?.symbol}` : ""
								}`
							: null,
						page: "Swap",
						path: "/swap",
					},
				]}
			/>
			<Page style={{ maxWidth: "1800px" }}>
				<Swap
					{...{
						...pageProps,
						independentField,
						inputCurrency: _inputCurrency,
						inputCurrencyId,
						outputCurrency: _outputCurrency,
						outputCurrencyId,
						recipient,
						typedValue,
					}}
				/>
			</Page>
		</Container>
	);
};
SwapPage.supportedChainId = {
	development: ALL_SUPPORTED_CHAIN_IDS,
	production: [SupportedChainId.AVALANCHE, SupportedChainId.TELOS],
	staging: ALL_SUPPORTED_CHAIN_IDS,
};
export default SwapPage;
