import { getChainIdCookie } from "@config/constants/networks";
import useActiveWagmi from "@hooks/useActiveWagmi";
import type { Offer } from "@hooks/useAggregator";
import { useAllTokens } from "@hooks/useCurrency";
import { ChevronRightIcon, Flex } from "@vapordex/uikit";
import { Typography } from "@vaporfi/uikit";
import { Fragment, memo } from "react";
import { unwrappedToken } from "utils/wrappedCurrency";

export default memo(function SwapRoute({ offer }: { offer: Offer }) {
	const { chainId, chain } = useActiveWagmi();
	const allTokens = useAllTokens(
		Number(chain?.id === chainId ? chainId : getChainIdCookie()),
	);
	return (
		<Flex
			flexWrap="wrap"
			width="100%"
			justifyContent="flex-end"
			alignItems="center"
		>
			{offer?.path?.map((token, i, path) => {
				const isLastItem: boolean = i === path.length - 1;
				const currency = unwrappedToken(allTokens[token]);
				return (
					<Fragment key={token}>
						<Flex alignItems="end">
							<Typography
								data-testid={`swap-route-item-${token}`}
								size="sm"
								style={{
									marginLeft: "var(--space-half)",
									marginRight: "var(--space-half)",
								}}
							>
								{currency?.symbol}
							</Typography>
						</Flex>
						{!isLastItem && <ChevronRightIcon width="12px" />}
					</Fragment>
				);
			})}
		</Flex>
	);
});
