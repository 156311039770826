import useStableCoinPrice from "@hooks/useStableCoinPrice";
import { useTokenPrice } from "@hooks/useTokenPrice";
import type { Currency, CurrencyAmount, Token } from "@vapordex/sdk";
import { useMemo } from "react";
import { useNetwork } from "wagmi";

export function getFiatValuePriceImpact(fiatValueOutput, outputTokenAmount) {
	if (fiatValueOutput === null || outputTokenAmount === null) {
		return null;
	}

	const fiatOutputNumber = Number.parseFloat(fiatValueOutput);
	const outputAmountNumber = Number.parseFloat(outputTokenAmount);
	let priceImpact = 100 - (outputAmountNumber / fiatOutputNumber) * 100;
	priceImpact = priceImpact < 0 ? 0 : priceImpact;
	return priceImpact;
}
export function useStablecoinValue(
	inputCurrencyAmount: CurrencyAmount<Token> | CurrencyAmount<Currency>,
	outputCurrencyAmount: CurrencyAmount<Token> | CurrencyAmount<Currency>,
) {
	const { chain } = useNetwork();
	const chainId = chain?.id?.toString();
	const isInputNative = inputCurrencyAmount?.currency?.isNative;
	const isOutputNative = outputCurrencyAmount?.currency?.isNative;

	const inputPriceData = useTokenPrice({
		chainId,
		isNativeCurrency: isInputNative,
		tokenAddress: isInputNative
			? null
			: ((inputCurrencyAmount?.currency as Token)?.address ?? null),
	});

	const outputPriceData = useTokenPrice({
		chainId,
		isNativeCurrency: isOutputNative,
		tokenAddress: isOutputNative
			? null
			: ((outputCurrencyAmount?.currency as Token)?.address ?? null),
	});

	const usdcPriceInput = useStableCoinPrice(inputCurrencyAmount?.currency);
	const usdcPriceOutput = useStableCoinPrice(outputCurrencyAmount?.currency);

	const { fiatValueInput, fiatValueOutput } = useMemo(() => {
		if (!inputCurrencyAmount || !outputCurrencyAmount) {
			return { fiatValueInput: null, fiatValueOutput: null };
		}
		if (
			!inputPriceData.isLoading &&
			!inputPriceData.isError &&
			inputPriceData.price !== null &&
			!outputPriceData.isLoading &&
			!outputPriceData.isError &&
			outputPriceData.price !== null
		) {
			const inputTokenAmount = Number.parseFloat(
				inputCurrencyAmount.toSignificant(6),
			);
			const fiatValueInput = inputPriceData.price * inputTokenAmount;
			const fiatValueOutput = (1 / outputPriceData.price) * fiatValueInput;
			return { fiatValueInput, fiatValueOutput };
		}
		// Fallback logic
		const usdcInput = usdcPriceInput
			?.quote(inputCurrencyAmount)
			?.toSignificant(6);
		const usdcOutput = usdcPriceOutput
			?.quote(outputCurrencyAmount)
			?.toSignificant(6);

		const fiatInputFallback = usdcInput ? Number.parseFloat(usdcInput) : null;
		const fiatOutputFallback = usdcOutput
			? Number.parseFloat(usdcOutput)
			: null;

		if (!fiatInputFallback || !fiatOutputFallback) {
			return { fiatValueInput: null, fiatValueOutput: null };
		}

		const adjustedFiatValueOutput =
			(1 / fiatOutputFallback) *
			fiatInputFallback *
			Number.parseFloat(outputCurrencyAmount.toSignificant(6));
		return {
			fiatValueInput: fiatInputFallback,
			fiatValueOutput: adjustedFiatValueOutput,
		};
	}, [
		inputCurrencyAmount,
		outputCurrencyAmount,
		inputPriceData,
		outputPriceData,
		usdcPriceInput,
		usdcPriceOutput,
	]);

	return { fiatValueInput, fiatValueOutput };
}
