import { useMemberCount } from "@hooks/useStratosphere";
import { Banner, BannerText, Button, Typography } from "@vaporfi/uikit";
import { STRATOSPHERE_URL } from "@vaporfi/utils";
import Link from "next/link";
import React from "react";

const StratosphereBanner = (props) => {
	const membersCount = useMemberCount(true);

	return (
		<Banner for="stratosphere" {...props}>
			<BannerText data-testid="strat-banner-text">
				Join {membersCount}+ members earning and saving more with
				<Typography
					data-testid="strat-banner-bold-strat-text"
					size="lg"
					className="font-manverse"
				>
					STRATOSPHERE
				</Typography>
			</BannerText>
			<Link href={STRATOSPHERE_URL} legacyBehavior>
				<Button
					data-testid="strat-banner-learn-more-btn"
					size="xs"
					color="blue"
					css={{
						boxShadow: "none",
						cursor: "pointer",
						height: "var(--space-6)",
					}}
				>
					<Typography color="light">learn more</Typography>
				</Button>
			</Link>
		</Banner>
	);
};

export default StratosphereBanner;
