import AggregatorDiamondABI from "@config/abi/AggregatorDiamond";
import { useUserIsStratosphereMember } from "@state/user/hooks";
import { AGGREGATOR_DEFAULT_FEE, AGGREGATOR_VAPE_FEE } from "config/constants";
import { ADDRESSES } from "config/constants/aggregator";
import { useCallback } from "react";
import { useTransactionAdder } from "state/transactions/hooks";
import { type Address, useContractWrite, usePrepareContractWrite } from "wagmi";
import useActiveWagmi from "./useActiveWagmi";
import type { Offer } from "./useAggregator";
import { useAggregatorRouterAddress } from "./useContract";

const getFunctionName = (offer: Offer) => {
	if (offer?.inputCurrency?.isNative) {
		return "swapNoSplitFromAVAX";
	}
	if (offer?.outputCurrency?.isNative) {
		return "swapNoSplitToAVAX";
	}
	return "swapNoSplit";
};

function getFee(adapters: readonly Address[], isStratosphere: boolean) {
	if (
		adapters.some(
			(adapter) => ADDRESSES.adapters[adapter]?.platform === "vapordex",
		)
	) {
		return AGGREGATOR_VAPE_FEE.toString();
	}

	return isStratosphere
		? (AGGREGATOR_DEFAULT_FEE - AGGREGATOR_DEFAULT_FEE * 0.01).toString()
		: AGGREGATOR_DEFAULT_FEE.toString();
}

export function useAggregatorSwapCallback(
	offer: Offer | undefined,
	allowedSlippage: number,
	recipientAddress: Address | null,
	onSwapStateChange: (isSwapping: boolean) => void,
) {
	const { account } = useActiveWagmi();
	const hasMintedNFT = useUserIsStratosphereMember();
	const addTransaction = useTransactionAdder();
	const onSuccess = useCallback(
		({ hash }) => {
			addTransaction(hash, { summary: "swap.success", type: "swap" });
			onSwapStateChange(false);
		},
		[addTransaction, onSwapStateChange],
	);
	const onError = useCallback(
		(args, overrides) => {
			fetch("/api/log", {
				body: JSON.stringify({ args, error: "SwapError", overrides }),
				method: "POST",
			});
			onSwapStateChange(false);
		},
		[onSwapStateChange],
	);

	const fee = getFee(offer?.adapters, hasMintedNFT);
	const to = recipientAddress ?? account;
	const functionName = getFunctionName(offer);

	const offerWithSlippage = {
		...offer,
		amounts: offer.amounts.map((amount: any, index: any) =>
			index === 0
				? BigInt(amount).toString()
				: (
						(BigInt(amount) * BigInt(10_000 - allowedSlippage)) /
						BigInt(10_000)
					).toString(),
		),
	};

	const trade = {
		adapters: offerWithSlippage.adapters,
		amountIn: offerWithSlippage.amounts[0],
		amountOut: offerWithSlippage.amounts.at(-1),
		path: offerWithSlippage.path,
	};
	const contractAddress = useAggregatorRouterAddress();
	const {
		config,
		error: prepareError,
		refetch,
	} = usePrepareContractWrite({
		abi: AggregatorDiamondABI,
		account,
		address: contractAddress,
		args: [trade, to, fee],
		enabled: offer?.path?.length > 0,
		functionName,
		value:
			functionName === "swapNoSplitFromAVAX"
				? (offer.amounts[0] as never)
				: (undefined as never),
	});

	const { error, writeAsync } = useContractWrite({
		...config,
		onError,
		onSuccess,
	});

	return {
		amountOut: offerWithSlippage.amounts.at(-1),
		callback: async () => {
			onSwapStateChange(true);
			return writeAsync();
		},
		error: prepareError ?? error,
		fee,
		refetch,
	};
}
