import CircleLoader from "@components/Loader/CircleLoader";
import { CheckmarkIcon, CloseIcon, LinkExternal } from "@vapordex/uikit";
import type { TransactionDetails } from "state/transactions/reducer";
import styled from "styled-components";
import { ExplorerDataType, getExplorerLink } from "utils";
import { useNetwork } from "wagmi";

const TransactionState = styled.div<{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) =>
		pending
			? theme.colors.primary
			: success
				? theme.colors.success
				: theme.colors.failure};
`;

export default function Transaction({ tx }: { tx: TransactionDetails }) {
	const { chain } = useNetwork();

	const summary = tx?.summary;
	const pending = !tx?.receipt;
	const success =
		!pending &&
		tx &&
		(tx.receipt?.status === 1 || tx.receipt?.status === undefined);

	if (!chain) return null;

	return (
		<TransactionState pending={pending} success={success}>
			<LinkExternal
				href={getExplorerLink(tx.hash, ExplorerDataType.TRANSACTION, chain)}
			>
				{summary ?? tx.hash}
			</LinkExternal>
			<IconWrapper pending={pending} success={success}>
				{pending ? (
					<CircleLoader />
				) : success ? (
					<CheckmarkIcon color="success" />
				) : (
					<CloseIcon color="failure" />
				)}
			</IconWrapper>
		</TransactionState>
	);
}
