import { cn } from "@vaporfi/utils";
import Image from "next/image";
import { type VariantProps, tv } from "tailwind-variants";

interface AdProps extends VariantProps<typeof styles> {
	className?: string;
	src: string;
	href: string;
}

const styles = tv({
	defaultVariants: {
		type: "video",
	},
	slots: {
		adWrapper:
			"relative flex items-center justify-center sm:h-[90px] sm:w-[728px] h-[45px] w-[364px]",
		base: "mt-8 flex w-full flex-col justify-center items-center",
		disclaimer: "text-center text-[10px] text-slate-300 opacity-50",
		player: "absolute h-full w-full object-cover",
	},
	variants: {
		type: {
			image: {
				player: "absolute h-full w-full object-cover",
			},
			video: {
				player: "absolute h-full w-full object-cover",
			},
		},
	},
});

export function Ad({ className, href, src, type }: AdProps) {
	const { adWrapper, base, disclaimer, player } = styles({ type });
	return (
		<div className={cn(base(), className)}>
			<span className={disclaimer()}>Ad</span>
			<div className={adWrapper()}>
				<a
					href={href}
					target="_blank"
					rel="noopener noreferrer"
					className={adWrapper()}
				>
					{type === "video" ? (
						<video autoPlay loop muted playsInline className={player()}>
							<source src={src} type="video/mp4" />
						</video>
					) : (
						<Image
							src={src}
							alt="Ad"
							className={player()}
							width={364}
							height={90}
						/>
					)}
				</a>
			</div>
		</div>
	);
}
