import type { ParsedUrlQuery } from "node:querystring";
import type { Currency, CurrencyAmount, TradeType } from "@vapordex/sdk";
import {
	DEFAULT_INPUT_CURRENCY,
	DEFAULT_OUTPUT_CURRENCY,
} from "config/constants/exchange";
import { useTradeExactIn, useTradeExactOut } from "hooks/Trades";
import useActiveWagmi from "hooks/useActiveWagmi";
import { type Offer, useAggregatorOffer } from "hooks/useAggregator";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isAddress } from "utils";
import { computeSlippageAdjustedAmounts } from "utils/exchange";
import { type AppState, useAppDispatch } from "../index";
import { useUserSlippageTolerance } from "../user/hooks";
import { useCurrencyBalances } from "../wallet/hooks";
import { Field, replaceSwapState } from "./actions";
import type { SwapState } from "./reducer";

import type { SupportedChainId } from "@/config/constants/chains";
import { ZERO } from "@config/constants";
import type { Trade } from "@vapordex/sdk";
import { NATIVE_SYMBOLS, tryParseAmount } from "@vaporfi/utils";
import { formatUnits } from "viem";
import { getTokenAddress } from "views/Swap/components/Chart/utils";
import { useAccount } from "wagmi";

export function useSwapState(): AppState["swap"] {
	return useSelector<AppState, AppState["swap"]>((state) => state.swap);
}

const BAD_RECIPIENT_ADDRESSES = new Set([
	"0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f", // v2 factory
	"0xf164fC0Ec4E93095b804a4795bBe1e041497b92a", // v2 router 01
	"0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D", // v2 router 02
]);

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */

function involvesAddress(
	trade: Trade<Currency, Currency, TradeType>,
	checksummedAddress: string,
): boolean {
	return (
		trade.route.path.some((token) => token.address === checksummedAddress) ||
		trade.route.pairs.some(
			(pair) => pair.liquidityToken.address === checksummedAddress,
		)
	);
}

// Get swap price for single token disregarding slippage and price impact
export function useSingleTokenSwapInfo(
	inputCurrencyId: string | undefined,
	inputCurrency: Currency | undefined,
	outputCurrencyId: string | undefined,
	outputCurrency: Currency | undefined,
): { [key: string]: number } {
	const token0Address = getTokenAddress(inputCurrencyId);
	const token1Address = getTokenAddress(outputCurrencyId);

	const parsedAmount = tryParseAmount("1", inputCurrency ?? undefined);

	const bestTradeExactIn = useTradeExactIn(
		parsedAmount,
		outputCurrency ?? undefined,
	);
	if (!inputCurrency || !outputCurrency || !bestTradeExactIn) {
		return null;
	}

	const inputTokenPrice = Number.parseFloat(
		bestTradeExactIn?.executionPrice?.toSignificant(6),
	);
	const outputTokenPrice = 1 / inputTokenPrice;

	return {
		[token0Address]: inputTokenPrice,
		[token1Address]: outputTokenPrice,
	};
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(
	independentField: Field,
	typedValue: string,
	inputCurrency: Currency | undefined,
	outputCurrency: Currency | undefined,
	recipient: string,
): {
	currencies: { [field in Field]?: Currency };
	currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
	parsedAmount: CurrencyAmount<Currency> | undefined;
	trade: Trade<Currency, Currency, TradeType> | undefined;
	inputError?: string;
} {
	const { account } = useActiveWagmi();

	const to: string | null =
		(recipient === null ? account : isAddress(recipient) || null) ?? null;

	const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
		inputCurrency ?? undefined,
		outputCurrency ?? undefined,
	]);

	const isExactIn: boolean = independentField === Field.INPUT;
	const parsedAmount = tryParseAmount(
		typedValue,
		(isExactIn ? inputCurrency : outputCurrency) ?? undefined,
	);

	const bestTradeExactIn = useTradeExactIn(
		isExactIn ? parsedAmount : undefined,
		outputCurrency ?? undefined,
	);
	const bestTradeExactOut = useTradeExactOut(
		inputCurrency ?? undefined,
		isExactIn ? undefined : parsedAmount,
	);

	const trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

	const currencyBalances = {
		[Field.INPUT]: relevantTokenBalances[0],
		[Field.OUTPUT]: relevantTokenBalances[1],
	};

	const currencies: { [field in Field]?: Currency } = {
		[Field.INPUT]: inputCurrency ?? undefined,
		[Field.OUTPUT]: outputCurrency ?? undefined,
	};

	let inputError: string | undefined;
	if (!account) {
		inputError = "Connect Wallet";
	}

	if (!parsedAmount) {
		inputError = inputError ?? "Enter an amount";
	}

	if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
		inputError = inputError ?? "Select a token";
	}

	const formattedTo = isAddress(to);
	if (!to || !formattedTo) {
		inputError = inputError ?? "Enter a recipient";
	} else if (
		BAD_RECIPIENT_ADDRESSES.has(formattedTo) ||
		(bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
		(bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
	) {
		inputError = inputError ?? "Invalid recipient";
	}

	const [allowedSlippage] = useUserSlippageTolerance();

	const slippageAdjustedAmounts =
		trade &&
		allowedSlippage &&
		computeSlippageAdjustedAmounts(trade, allowedSlippage);

	// compare input balance to max input based on version
	const [balanceIn, amountIn] = [
		currencyBalances[Field.INPUT],
		slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
	];

	if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
		inputError = `Insufficient ${amountIn.currency.symbol} balance`;
	}

	return {
		currencies,
		currencyBalances,
		inputError,
		parsedAmount,
		trade: trade ?? undefined,
	};
}

export function useAggregatedSwapInfo(
	independentField: Field,
	typedValue: string,
	inputCurrency: Currency | undefined,
	outputCurrency: Currency | undefined,
	isSwapPending: boolean,
): {
	currencies: { [field in Field]?: Currency };
	currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
	parsedAmount: CurrencyAmount<Currency> | undefined;
	parsedOutputAmount: CurrencyAmount<Currency> | undefined;
	offer: Offer | undefined;
	inputError?: string;
	onRefresh?: () => void;
} {
	const { address: account } = useAccount();

	const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
		inputCurrency ?? undefined,
		outputCurrency ?? undefined,
	]);

	const parsedAmount = tryParseAmount(typedValue, inputCurrency ?? undefined);

	// Fetch offer
	const { offer, refetch: onRefresh } = useAggregatorOffer(
		inputCurrency,
		outputCurrency,
		typedValue,
		isSwapPending,
	);
	const amountOut = offer?.amounts[offer?.amounts.length - 1] || ZERO;
	const formattedAmountOut = formatUnits(amountOut, outputCurrency?.decimals);
	const parsedOutputAmount = tryParseAmount(
		formattedAmountOut,
		outputCurrency ?? undefined,
	);

	const currencyBalances = {
		[Field.INPUT]: relevantTokenBalances[0],
		[Field.OUTPUT]: relevantTokenBalances[1],
	};

	const currencies: { [field in Field]?: Currency } = useMemo(
		() => ({
			[Field.INPUT]: inputCurrency ?? undefined,
			[Field.OUTPUT]: outputCurrency ?? undefined,
		}),
		[inputCurrency, outputCurrency],
	);

	const inputError = useMemo(() => {
		let error = "";
		if (!account) {
			error = "Connect Wallet";
		}

		if (!parsedAmount) {
			error = error ?? "Enter an amount";
		}

		if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
			error = error ?? "Select a token";
		}

		if (offer?.adapters?.length === 0) {
			error = error ?? "No routes available";
		}

		return error;
	}, [account, currencies, offer?.adapters?.length, parsedAmount]);
	// const formattedTo = isAddress(to)
	// if (!to || !formattedTo) {
	//   inputError = inputError ?? t('Enter a recipient')
	// } else if (BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 || involvesRouter(offer, formattedTo)) {
	//   inputError = inputError ?? t('Invalid recipient')
	// }

	// const [allowedSlippage] = useUserSlippageTolerance()

	// const slippageAdjustedAmounts = offer && allowedSlippage && computeSlippageAdjustedAmounts(offer, allowedSlippage)

	// compare input balance to max input based on version
	// const [balanceIn, amountIn] = [
	//   currencyBalances[Field.INPUT],
	//   slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
	// ]

	// if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
	//   inputError = t('Insufficient %symbol% balance', { symbol: amountIn.currency.symbol })
	// }

	return {
		currencies,
		currencyBalances,
		inputError,
		offer: offer ?? undefined,
		onRefresh,
		parsedAmount,
		parsedOutputAmount,
	};
}

function parseCurrencyFromURLParameter(urlParam: any): string {
	if (typeof urlParam === "string") {
		const valid = isAddress(urlParam);
		if (valid) return valid;
		const param = urlParam.toUpperCase();
		if (NATIVE_SYMBOLS.includes(param)) return param;

		if (valid === false) return "AVAX";
	}
	return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
	return typeof urlParam === "string" &&
		!Number.isNaN(Number.parseFloat(urlParam))
		? urlParam
		: "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
	return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
		? Field.OUTPUT
		: Field.INPUT;
}

const ADDRESS_REGEX = /^0x[\dA-Fa-f]{40}$/;
function validatedRecipient(recipient: any): string | null {
	if (typeof recipient !== "string") return null;
	const address = isAddress(recipient);
	if (address) return address;
	if (ADDRESS_REGEX.test(recipient)) return recipient;
	return null;
}

export function queryParametersToSwapState(
	parsedQs: ParsedUrlQuery,
	chainId: SupportedChainId,
): SwapState {
	let inputCurrency =
		parseCurrencyFromURLParameter(parsedQs.inputCurrency) ||
		DEFAULT_INPUT_CURRENCY[chainId];
	let outputCurrency =
		parseCurrencyFromURLParameter(parsedQs.outputCurrency) ||
		DEFAULT_OUTPUT_CURRENCY[chainId];
	if (inputCurrency === outputCurrency) {
		if (typeof parsedQs.outputCurrency === "string") {
			inputCurrency = "";
		} else {
			outputCurrency = "";
		}
	}

	const recipient = validatedRecipient(parsedQs.recipient);

	return {
		[Field.INPUT]: {
			currencyId: inputCurrency,
		},
		[Field.OUTPUT]: {
			currencyId: outputCurrency,
		},
		derivedPairDataById: {},
		independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
		pairDataById: {},
		recipient,
		typedValue: parseTokenAmountURLParameter(parsedQs.inputAmount),
	};
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
	| {
			inputCurrencyId: string | undefined;
			outputCurrencyId: string | undefined;
	  }
	| undefined {
	const { chainId } = useActiveWagmi();
	const dispatch = useAppDispatch();
	const { query } = useRouter();
	const [result, setResult] = useState<
		| {
				inputCurrencyId: string | undefined;
				outputCurrencyId: string | undefined;
		  }
		| undefined
	>();

	useEffect(() => {
		if (!chainId) return;
		const parsed = queryParametersToSwapState(query, chainId);

		dispatch(
			replaceSwapState({
				field: parsed.independentField,
				inputCurrencyId: parsed[Field.INPUT].currencyId,
				outputCurrencyId: parsed[Field.OUTPUT].currencyId,
				recipient: null,
				typedValue: parsed.typedValue,
			}),
		);

		setResult({
			inputCurrencyId: parsed[Field.INPUT].currencyId,
			outputCurrencyId: parsed[Field.OUTPUT].currencyId,
		});
	}, [dispatch, chainId, query]);

	return result;
}
export const useLPApr = (_pair) => {
	// const { data: poolData } = useSWRImmutable(
	//   pair ? ['LP7dApr', pair.liquidityToken.address] : null,
	//   async () => {
	//     const timestampsArray = getDeltaTimestamps()
	//     const blocks = await getBlocksFromTimestamps(timestampsArray, 'desc', 1000)
	//     const [block24h, block48h, block7d, block14d] = blocks ?? []
	//     const { error, data } = await fetchPoolData(block24h.number, block48h.number, block7d.number, block14d.number, [
	//       pair.liquidityToken.address.toLowerCase(),
	//     ])
	//     if (error) return null
	//     const formattedPoolData = parsePoolData(data?.now)
	//     const formattedPoolData24h = parsePoolData(data?.oneDayAgo)
	//     const formattedPoolData48h = parsePoolData(data?.twoDaysAgo)
	//     const formattedPoolData7d = parsePoolData(data?.oneWeekAgo)
	//     const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo)
	//     const current: FormattedPoolFields | undefined = formattedPoolData[pair.liquidityToken.address.toLowerCase()]
	//     const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[pair.liquidityToken.address.toLowerCase()]
	//     const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[pair.liquidityToken.address.toLowerCase()]
	//     const week: FormattedPoolFields | undefined = formattedPoolData7d[pair.liquidityToken.address.toLowerCase()]
	//     const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[pair.liquidityToken.address.toLowerCase()]
	//     const [volumeUSD] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
	//     const [volumeUSDWeek] = getChangeForPeriod(current?.volumeUSD, week?.volumeUSD, twoWeeks?.volumeUSD)
	//     const liquidityUSD = current ? current.reserveUSD : 0
	//     const { lpApr7d } = getLpFeesAndApr(volumeUSD, volumeUSDWeek, liquidityUSD)
	//     return lpApr7d ? { lpApr7d } : null
	//   },
	//   {
	//     refreshInterval: SLOW_INTERVAL,
	//   },
	// )

	// return poolData

	return null;
};
