import { type Currency, CurrencyAmount } from "@vapordex/sdk";
import { Pair } from "@vapordex/sdk";
import { VaporDEXPairABI } from "config/abi/VaporDEXPair";
import { useMemo } from "react";
import { useMultipleContractSingleData } from "../state/multicall/hooks";

export enum PairState {
	LOADING = 0,
	NOT_EXISTS = 1,
	EXISTS = 2,
	INVALID = 3,
}

export function usePairs(
	currencies: [Currency | undefined, Currency | undefined][],
): [PairState, Pair | null][] {
	const tokens = useMemo(
		() =>
			currencies.map(([currencyA, currencyB]) => [
				currencyA?.wrapped,
				currencyB?.wrapped,
			]),
		[currencies],
	);

	const pairAddresses = useMemo(
		() =>
			tokens.map(([tokenA, tokenB]) => {
				try {
					return tokenA && tokenB && !tokenA.equals(tokenB)
						? Pair.getAddress(tokenA, tokenB)
						: undefined;
				} catch (error: any) {
					// Debug Invariant failed related to this line
					console.error(
						error.msg,
						`- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
						`chainId: ${tokenA?.chainId}`,
					);

					return;
				}
			}),
		[tokens],
	);

	const results = useMultipleContractSingleData(
		pairAddresses,
		VaporDEXPairABI,
		"getReserves",
	);

	return useMemo(() => {
		return results.map((result, i) => {
			const { loading, result: reserves } = result;
			const tokenA = tokens[i][0];
			const tokenB = tokens[i][1];

			if (loading) return [PairState.LOADING, null];
			if (!tokenA || !tokenB || tokenA.equals(tokenB))
				return [PairState.INVALID, null];

			if (!reserves) return [PairState.NOT_EXISTS, null];
			const [reserve0, reserve1] = reserves;

			const [token0, token1] = tokenA.sortsBefore(tokenB)
				? [tokenA, tokenB]
				: [tokenB, tokenA];

			return [
				PairState.EXISTS,
				new Pair(
					CurrencyAmount.fromRawAmount(token0, reserve0?.toString()),
					CurrencyAmount.fromRawAmount(token1, reserve1?.toString()),
				),
			];
		});
	}, [results, tokens]);
}

export function usePair(
	tokenA?: Currency,
	tokenB?: Currency,
): [PairState, Pair | null] {
	const pairCurrencies = useMemo<[Currency, Currency][]>(
		() => [[tokenA, tokenB]],
		[tokenA, tokenB],
	);
	return usePairs(pairCurrencies)[0];
}
