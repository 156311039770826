import ConnectWalletButton from "@components/ConnectWalletButton";
import { AutoRow } from "@components/Layout/Row";
import {
	Flex,
	type InjectedModalProps,
	Modal,
	ModalBody,
	Text,
} from "@vapordex/uikit";
import { Button } from "@vaporfi/uikit";
import useActiveWagmi from "hooks/useActiveWagmi";
import orderBy from "lodash/orderBy";
import { useCallback } from "react";
import { useAppDispatch } from "state";
import { clearAllTransactions } from "state/transactions/actions";
import {
	isTransactionRecent,
	useAllTransactions,
} from "state/transactions/hooks";
import type { TransactionDetails } from "state/transactions/reducer";
import Transaction from "./Transaction";

function renderTransactions(transactions: TransactionDetails[]) {
	return (
		<Flex flexDirection="column">
			{transactions.map((tx) => {
				return <Transaction key={tx.hash + tx.addedTime} tx={tx} />;
			})}
		</Flex>
	);
}

const TransactionsModal: React.FC<
	React.PropsWithChildren<InjectedModalProps>
> = ({ onDismiss }) => {
	const { account, chainId } = useActiveWagmi();
	const dispatch = useAppDispatch();
	const allTransactions = useAllTransactions();

	const sortedRecentTransactions = orderBy(
		Object.values(allTransactions).filter(isTransactionRecent),
		"addedTime",
		"desc",
	);

	const pending = sortedRecentTransactions.filter((tx) => !tx.receipt);
	const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt);

	const clearAllTransactionsCallback = useCallback(() => {
		if (chainId) dispatch(clearAllTransactions({ chainId }));
	}, [dispatch, chainId]);

	return (
		<Modal
			data-testid="transactions-modal-container"
			title={"Recent Transactions"}
			onDismiss={onDismiss}
			style={{ backgroundColor: "rgba(0,0,0,1)", color: "#d6dada" }}
		>
			{account ? (
				<ModalBody>
					{pending.length > 0 || confirmed.length > 0 ? (
						<>
							<AutoRow mb="1rem" style={{ justifyContent: "space-between" }}>
								<Text color="#d6dada">{"Recent Transactions"}</Text>
								<Button
									variant="outline"
									color="blue"
									size="xs"
									onClick={clearAllTransactionsCallback}
								>
									{"clear all"}
								</Button>
							</AutoRow>
							{renderTransactions(pending)}
							{renderTransactions(confirmed)}
						</>
					) : (
						<Text color="#d6dada">{"No recent transactions"}</Text>
					)}
				</ModalBody>
			) : (
				<ConnectWalletButton data-testid="connect-wallet-btn-modal" />
			)}
		</Modal>
	);
};

export default TransactionsModal;
